// src/components/GradientDefs.jsx
import React from "react";

const GradientDefs = () => (
  <svg width="0" height="0">
    <defs>
      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%">
        <stop offset="0%" style={{ stopColor: "#B7E0F1", stopOpacity: 1 }} />
        <stop offset="100%" style={{ stopColor: "#B7E0F1", stopOpacity: 0.3 }} />
      </linearGradient>
    </defs>
  </svg>
);

export default GradientDefs;
