import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { IoIosArrowDown } from "react-icons/io";


const TraineeForm = () => {
    const form = useRef(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [portfolio, setPortfolio] = useState(null);
    const [courseComponents, setCourseComponents] = useState('');
    const [learningMethods, setLearningMethods] = useState('');
    const [teachingMethods, setTeachingMethods] = useState('');
    const [suggestions, setSuggestions] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        const templateParams = {
            user_name: name,
            user_email: email,
            user_phoneNumber: phoneNumber,
            user_linkedin: linkedin,
            user_portfolio: portfolio ? portfolio.name : '', // Use the file name or handle file upload separately
            user_courseComponents: courseComponents,
            user_learningMethods: learningMethods,
            user_teachingMethods: teachingMethods,
            user_suggestions: suggestions,
        };

        emailjs.send('service_l8j1ytl', 'template_rs6u3wu', templateParams, 'rcWUEyKg3TYfCLt2-')
            .then(
                () => {
                    console.log('SUCCESS!');
                    setIsSubmitted(true);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
    };

    const handleFileChange = (e) => {
        const file = e.target.files ? e.target.files[0] : null;
        setPortfolio(file);
    };

    if (isSubmitted) {
        return (
            <div className="w-[90%] relative sm:w-full width mx-auto flex items-center justify-center px-2 md:px-10 py-10">
                <div className="w-[32rem] h-[10rem] md:h-auto px-3 py-5 md:px-10 md:py-10 rounded-xl flex flex-col justify-center items-center gap-4 z-20 bg-primary border-[0.03rem] border-border">
                    <p className="text-text-light text-sm md:text-[1rem] xl:text-lg font-RobotoBold tracking-wide text-center">
                        Your message has been superpositioned in our inbox - we’ll collapse it into action soon!
                    </p>
                    <p className="text-center font-RobotoRegular text-xs md:text-sm tracking-wide text-text-dark">
                        Want to connect with us in a snap? Follow us on our socials for quantum-speed updates and interactions.
                    </p>
                </div>
                <div className="absolute -z-2 left-1/2 top-[60%] transform -translate-x-1/2 -translate-y-1/2 inset-0 md:w-[80%] md:h-[12rem]"
                    style={{
                        background: "radial-gradient(circle at center, rgba(37,57,59,0.6), transparent)",
                        filter: "blur(70px)",
                    }}
                ></div>
            </div>
        );
    }

    return (
        <div className="relative w-full md:w-[80%] max-w-5xl mx-auto flex md:flex-row flex-col md:gap-0 gap-8 items-center justify-center px-2 md:px-10 py-10">
            <form className='w-full md:w-full bg-primary border-[0.06rem] border-border rounded-3xl flex flex-col justify-center items-start px-4 py-6 md:px-10 md:py-12' onSubmit={handleSubmit} ref={form}>
                <h1 className="w-full text-left md:text-[1.25rem] text-[1rem] text-text-light font-medium mb-4 px-2">Become our Trainer</h1>

                <div className="w-full md:w-[70%] md:mb-4 mb-2 input-gradient">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className="w-full md:w-[70%] md:mb-4 mb-2 flex md:flex-row flex-col text-sm items-center justify-center md:gap-2">
                    <div className="w-full mb-2 md:mb-0 md:w-[50%] input-gradient">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="w-full md:w-[50%] input-gradient">
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Phone number"
                            className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                            required
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                </div>

                <div className="w-full md:w-[70%] md:mb-4 mb-2 input-gradient">
                    <input
                        type="url"
                        id="linkedin"
                        name="linkedin"
                        placeholder="LinkedIn Profile"
                        className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                        required
                        value={linkedin}
                        onChange={(e) => setLinkedin(e.target.value)}
                    />
                </div>

                <div className="w-full md:mb-4 mb-2 flex flex-col items-start justify-center gap-1 mt-4">
                    <label className='text-text-light md:text-[1rem] text-sm my-2 px-1 text-left w-full'>Upload your Tech & Teach Portfolio:</label>
                    <input
                        type="file"
                        id="portfolio"
                        name="portfolio"
                        className="w-full font-extralight text-text-dark md:text-[1rem] text-xs"
                        required
                        onChange={handleFileChange}
                    />
                </div>

                <div className='w-full flex flex-col md:flex-row items-center justify-between'>
                    <div className='w-full md:w-[49%] flex flex-col'>
                        <label className='text-text-light md:text-[1rem] text-sm my-2 px-1 text-left w-full h-[3rem] md:h-[2.5rem]'>If you were to design a course on Quantum or AI, what would be its key components?</label>
                        <div className="w-full md:mb-4 mb-2 input-gradient">
                            <textarea
                                id="courseComponents"
                                name="courseComponents"
                                className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] h-[10rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                                required
                                value={courseComponents}
                                onChange={(e) => setCourseComponents(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='w-full md:w-[49%] flex flex-col'>
                        <label className='text-text-light md:text-[1rem] text-sm my-2 px-1 text-left w-full h-[4rem] md:h-[2.5rem]'>In what ways do you stay updated and continue learning in the rapidly evolving fields of Quantum and AI?</label>
                        <div className="w-full md:mb-4 mb-2 input-gradient">
                            <textarea
                                id="learningMethods"
                                name="learningMethods"
                                className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] h-[10rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                                required
                                value={learningMethods}
                                onChange={(e) => setLearningMethods(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className='w-full flex flex-col md:flex-row items-center justify-between'>
                    <div className='w-full md:w-[49%] flex flex-col'>
                        <label className='text-text-light md:text-[1rem] text-sm my-2 px-1 text-left w-full'>How do you make complex topics in Quantum and AI accessible and engaging for students?</label>
                        <div className="w-full md:mb-4 mb-2 input-gradient">
                            <textarea
                                id="teachingMethods"
                                name="teachingMethods"
                                className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] h-[10rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                                required
                                value={teachingMethods}
                                onChange={(e) => setTeachingMethods(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='w-full md:w-[49%] flex flex-col'>
                        <label className='text-text-light md:text-[1rem] text-sm my-2 px-1 text-left w-full'>Any suggestions/information to share</label>
                        <div className="w-full md:mb-4 mb-2 input-gradient">
                            <textarea
                                id="suggestions"
                                name="suggestions"
                                className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] h-[10rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                                required
                                value={suggestions}
                                onChange={(e) => setSuggestions(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-full flex justify-center">
                    <button
                        type="submit"
                        className="w-[8.5rem] h-[2.25rem] input-gradient font-extralight md:text-[1rem] text-sm text-text-light flex items-center justify-center px-4 py-2 rounded-lg"
                    >
                        Submit
                    </button>
                </div>
            </form>
            <div
                className="absolute -z-10 left-[20%] top-[15%] transform -translate-x-1/2 -translate-y-1/2 rounded-full md:w-[25rem] md:h-[25rem]"
                style={{
                    background: "radial-gradient(circle at center, rgba(37,57,59,0.6), transparent)",
                    filter: "blur(40px)",
                }}
            ></div>
            <div
                className="absolute -z-10 left-[80%] top-[85%] transform -translate-x-1/2 -translate-y-1/2 rounded-full md:w-[20rem] md:h-[20rem]"
                style={{
                    background: "radial-gradient(circle at center, rgba(37,57,59,0.6), transparent)",
                    filter: "blur(40px)",
                }}
            ></div>
        </div>
    );
};











const Form = () => {
    const form = useRef(null);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [currentRole, setCurrentRole] = useState('');
    const [organization, setOrganization] = useState('');
    const [background, setBackground] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [subject, setSubject] = useState('Select Course');
    const [enquiry, setEnquiry] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleDropdownToggle = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleSelect = (value) => {
        setSubject(value);
        setDropdownOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            user_name: name,
            user_email: email,
            user_phoneNumber: phone,
            user_subject: subject,
            user_organisation: organization,
            user_background: background,
            user_enquiry: enquiry,
            user_currentRole: currentRole
        };

        emailjs.send('service_l8j1ytl', 'template_rs6u3wu', templateParams, 'rcWUEyKg3TYfCLt2-')
            .then(
                () => {
                    console.log('SUCCESS!');
                    setIsSubmitted(true);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    if (isSubmitted) {
        return (
            <div className="w-[90%] relative sm:w-full width mx-auto flex items-center justify-center px-2 md:px-10 py-10">
                <div className="w-[32rem] h-[10rem] md:h-auto px-3 py-5 md:px-10 md:py-10 rounded-xl flex flex-col justify-center items-center gap-4 z-20 bg-primary border-[0.03rem] border-border">
                    <p className="text-text-light text-sm md:text-[1rem] xl:text-lg font-RobotoBold tracking-wide text-center">
                        Your message has been received! We'll get back to you soon.
                    </p>
                    <p className="text-center font-RobotoRegular text-xs md:text-sm tracking-wide text-text-dark">
                        Want to connect with us quickly? Follow us on our socials for the latest updates and interactions.
                    </p>
                </div>
                <div
                    className="absolute -z-2 left-1/2 top-[60%] transform -translate-x-1/2 -translate-y-1/2 inset-0 md:w-[80%] md:h-[12rem]"
                    style={{
                        background: "radial-gradient(circle at center, rgba(37,57,59,0.6), transparent)",
                        filter: "blur(70px)",
                    }}
                ></div>
            </div>
        );
    }

    return (
        <div className="relative w-full md:w-[80%] max-w-5xl mx-auto flex md:flex-row flex-col md:gap-0 gap-8 items-center justify-center px-2 md:px-10 py-10">
            <form
                className='w-full md:w-[70%] bg-primary border-[0.06rem] border-border rounded-3xl flex flex-col justify-center items-center px-4 py-6 md:px-10 md:py-12'
                onSubmit={handleSubmit}
                ref={form}
            >
                <h1 className="w-full text-left md:text-[1.25rem] text-[1rem] text-text-light font-medium mb-4 px-2">Course Enquiry</h1>

                <div className="w-full md:mb-4 mb-2 input-gradient">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Name"
                        className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>

                <div className="w-full md:mb-4 mb-2 flex md:flex-row flex-col text-sm items-center justify-center md:gap-2 ">
                    <div className="w-full mb-2 md:mb-0 md:w-[50%] input-gradient">
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email Address"
                            className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="w-full md:w-[50%] input-gradient">
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            placeholder="Phone"
                            className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                            required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>

                <div className="w-full md:mb-4 mb-2 input-gradient">
                    <input
                        type="text"
                        id="currentRole"
                        name="currentRole"
                        placeholder="Current Role"
                        className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                        required
                        value={currentRole}
                        onChange={(e) => setCurrentRole(e.target.value)}
                    />
                </div>

                <div className="w-full md:mb-4 mb-2 input-gradient">
                    <input
                        type="text"
                        id="organization"
                        name="organization"
                        placeholder="Organization"
                        className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                        required
                        value={organization}
                        onChange={(e) => setOrganization(e.target.value)}
                    />
                </div>
                <label className='text-text-light md:text-[1rem] text-sm my-2 px-1 text-left w-full'>Background (Briefly describe your academic or professional background)</label>
                <div className="w-full md:mb-4 mb-2 input-gradient">
                    <textarea
                        id="background"
                        name="background"
                        className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] h-[10rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                        required
                        value={background}
                        onChange={(e) => setBackground(e.target.value)}
                    />
                </div>

                <div className="w-full input-gradient md:mb-4 text-sm mb-2 relative">
                    <div
                        className="pr-5 pl-2 py-2 md:py-3 w-full flex items-center justify-between bg-gradient-inputbox rounded-xl cursor-pointer"
                        onClick={handleDropdownToggle}
                    >
                        <div className="px-2 font-extralight text-text-light bg-gradient-inputbox md:text-[1rem] text-sm">
                            {subject}
                        </div>
                        <div>
                            <IoIosArrowDown color='#F9FDFE' size={20} />
                        </div>
                    </div>
                    {dropdownOpen && (
                        <div className="absolute w-full left-0 top-8 md:top-12 mt-1 p-4 bg-gradient-inputbox rounded-xl z-10">
                            <div
                                className="px-2 py-2 cursor-pointer hover:bg-[#1B6B8F]/40 rounded-lg text-text-light md:text-[1rem] text-sm"
                                onClick={() => handleSelect('Applied Quantum')}
                            >
                                Applied Quantum
                            </div>
                            <div
                                className="px-2 py-2 cursor-pointer hover:bg-[#1B6B8F]/40 rounded-lg text-text-light md:text-[1rem] text-sm"
                                onClick={() => handleSelect('Applied Artificial Intelligence')}
                            >
                                Applied Artificial Intelligence
                            </div>
                            <div
                                className="px-2 py-2 cursor-pointer hover:bg-[#1B6B8F]/40 rounded-lg text-text-light md:text-[1rem] text-sm"
                                onClick={() => handleSelect('Applied Quantum and Artificial Intelligence')}
                            >
                                Both
                            </div>
                        </div>
                    )}
                </div>
                <label className='text-text-light md:text-[1rem] text-sm my-2 px-1 text-left w-full'>Your Enquiry (Briefly describe your question or interest)</label>
                <div className="w-full md:mb-4 mb-2 input-gradient">
                    <textarea
                        id="enquiry"
                        name="enquiry"
                        className="px-2 py-2 md:p-3 w-full font-extralight text-text-light md:text-[1rem] h-[10rem] text-sm rounded-xl bg-gradient-inputbox outline-none"
                        required
                        value={enquiry}
                        onChange={(e) => setEnquiry(e.target.value)}
                    />
                </div>

                <div className="w-full flex justify-center">
                    <button
                        type="submit"
                        className="w-[8.5rem] h-[2.25rem] input-gradient font-extralight md:text-[1rem] text-sm text-text-light flex items-center justify-center px-4 py-2 rounded-lg"
                    >
                        Submit
                    </button>
                </div>
            </form>
            <div
                className="absolute -z-10 left-[20%] top-[15%] transform -translate-x-1/2 -translate-y-1/2 rounded-full md:w-[25rem] md:h-[25rem]"
                style={{
                    background: "radial-gradient(circle at center, rgba(37,57,59,0.6), transparent)",
                    filter: "blur(40px)",
                }}
            ></div>
            <div
                className="absolute -z-10 left-[80%] top-[85%] transform -translate-x-1/2 -translate-y-1/2 rounded-full md:w-[20rem] md:h-[20rem]"
                style={{
                    background: "radial-gradient(circle at center, rgba(37,57,59,0.6), transparent)",
                    filter: "blur(40px)",
                }}
            ></div>
        </div>
    );
};






export { Form, TraineeForm };