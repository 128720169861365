import React from 'react'
import Header from '../components/header';
import Footer from '../components/footer';
import SimpleHeroSection from '../components/simpleHeroSection';
import { CourseComingSoon } from "../components/boxCourse";
import {CourseComingSoonData} from '../data/coursesData';

export default function courses() {
  return (
    <main className="philosopher bg-primary w-full flex flex-col justify-center items-center">
        
        <Header />
    <div className="w-full widthScreen flex flex-col justify-center items-center">


    <SimpleHeroSection 
                title="Courses" 
                content="Gain hands-on experience through curriculum and projects designed by scientists, engineers, and developers from our partner companies." 
            />
            <section className="relative w-full flex flex-col items-center justify-center px-10 py-20 gap-28">
                {CourseComingSoonData.map((course, index) => (
                    <CourseComingSoon
                        key={course.id}
                        name={course.name}
                        event={index % 2 === 0 ? "true" : "false"}
                        img={course.img}
                        note={course.note}
                        smallpara={course.smallpara}
                    />
                ))}
            </section>

    </div>
    <Footer />
</main>
  )
}
