import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/footer';
import HomepageIllustration from "../images/HomepageIllustration.png";
import community from "../images/community.png";
import TitleContent from '../components/TitleContent';
import { HomeIcons, Partners, CourseName, Comments } from '../data/icons';
import PartnersBox from '../components/partnersBox';
import { BoxIcon } from '../components/boxIcon';
import { BoxCourse, BoxCourseComment } from '../components/boxCourse';

export default function Home() {
    const navigate = useNavigate();
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };


    const handleJoinUsClick = () => {
        navigate('/Enquiry');
    };

    return (
        <main className="philosopher bg-primary w-full flex flex-col justify-center items-center">
              <Header />
            <div className="w-full widthScreen flex flex-col justify-center items-center">

              
                <section className="relative w-full width flex items-center justify-center gap-20 h-[15rem] md:h-[33.75rem] px-5">

                    <h1 className="w-full md:w-[33rem] absolute top-[55%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[2rem] md:text-[3.2rem] leading-tight font-medium text-text-light text-center">
                        Enhancing the learning experience through application
                    </h1>
                    <img 
                        src={HomepageIllustration} 
                        alt="Homepage Illustration" 
                        width={1243} 
                        height={534} 
                        className="object-contain md:block hidden" 
                    />

                </section>

                <section className="w-full width flex flex-col items-center justify-center px-2 md:px-10 py-10 md:py-20 gap-20">
                    <TitleContent 
                        title="Why QuAcademy" 
                        content="At QuAcademy, we bridge the gap between theoretical knowledge and practical application. Our specialized courses in Applied Quantum Computing and Artificial Intelligence are designed to equip you with the skills needed to excel in the fast-evolving tech landscape." 
                    />

                    <div className="w-full md:w-[80%] flex flex-wrap justify-center items-center gap-10">
                        {HomeIcons.map((icon) => (
                            <BoxIcon 
                                key={icon.id} 
                                img={icon.img} 
                                title={icon.title} 
                                content={icon.content} 
                            />
                        ))}
                    </div>

                </section>
                <section className="relative w-full width flex flex-col  items-center justify-center px-2 md:px-10 py-20 gap-20 ">
        <TitleContent title="Applied Deep Technologies Courses"  content="" />
        <div className="w-full flex flex-col md:flex-row items-center justify-center px-3 ">
          {/* <div className="w-full md:w-[32%] flex flex-col items-start justify-center gap-5">
            <h1 className="w-full font-medium md:text-[2rem] text-2xl   text-text-light">Our Services</h1>
            <p className="w-full  md:text-[1rem] text-sm  font-thin text-text-dark text-left">We are focused on delivering exclusive training on Data Science, Artificial Intelligence and Quantum computing to Students, Job aspirants and Professionals.
              Our training programs, comprising experienced professionals, are designed in such a way that it meets our vision, which is to provide the finest Applied Quantum, Artificial Intelligence and Data Science Experts globally.</p>

          </div> */}
          <div className="w-full md:w-[70%] flex flex-col md:flex-row justify-center items-center gap-10">
            {
              CourseName.map((course) => (
                <div key={course.id} className="flex ">
                  <BoxCourse name={course.name} img={course.img} content={course.content} />
                </div>
              ))
            }
          </div>
        </div>
      </section>
      <section className="relative w-full width flex flex-col  items-center justify-center px-2 md:px-10 py-20 gap-10 ">
      <TitleContent title="Our Course Takers Success Stories"  content="Our alumni who have gone on to make significant contributions in the fields of Quantum and AI. Learn how QuAcademy has helped them achieve their career goals and make an impact in the tech industry. " />
      <div className="w-full flex flex-wrap justify-center items-center gap-10  py-5">
              {
                Comments.map((comment) => (
                  <div key={comment.id} className="flex ">
                    <BoxCourseComment name={comment.name}  comment={comment.comment} />
                  </div>
                ))
              }
            </div>

      </section>
                <section className="relative w-full width flex flex-col md:flex-row items-center justify-center px-2 md:px-10 py-20 gap-16">
                  <img src={community} alt="Community" width={400} height={400} className="object-contain" />
                  <div className="w-[100%] md:w-[37.5rem] flex flex-col items-center md:items-start justify-center gap-5">

                      <h1 className="w-full font-medium md:text-[2rem] text-2xl text-center md:text-left text-text-light">Be Part of a Thriving Community</h1>
                      <div className="flex flex-col md:flex-row gap-5 items-center justify-center">
                        <p className="md:text-[1rem] text-sm font-thin text-text-dark text-center md:text-left">
                            At QuAcademy, you&apos;re not just enrolling in a course—you&apos;re joining a vibrant community of learners, researchers, and professionals. Engage in forums, attend webinars, and collaborate on projects with peers from around the world.
                        </p>
                        <button 
                         onClick={() => {
                          scrollToTop();
                          
                          handleJoinUsClick();}}
                            className="px-6 py-4 bg-border text-text-light w-[14rem] rounded-xl"
                        >
                            Join us
                        </button>
                      </div>

                  </div>

                </section>
                <section className="relative w-full width flex flex-col  items-center justify-center px-2 md:px-10 py-20 gap-10 ">
        <div className="w-full border-gradient rounded-lg ">
          <div className="bg-primary w-full flex flex-col justify-center items-start gap-8 py-10">
          <TitleContent title="Hiring Companies"  content="" />
            <div className="w-full flex flex-wrap justify-center items-center gap-10  py-5">
              {
                Partners.map((partner) => (
                  <div key={partner.id} className="flex ">
                    <PartnersBox img={partner.img} />
                  </div>
                ))
              }
            </div>

          </div>
        </div>

      </section>
               

            </div>
            <Footer />
        </main>
    );
}
