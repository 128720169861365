"use client";
import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa';

// Define the AboutUsCard component
const AboutUsCard = ({ img, name, content }) => {
    return (
        <div className="relative w-[20rem] h-[20rem] flex flex-col justify-center items-center px-7 py-5 gap-2 bg-gradient-boxcolor border-[0.045rem] border-border rounded-[1.5rem]">
            <div className="absolute -top-2 w-[5rem] h-[5rem] rounded-[1.25rem] bg-gradient-iconboxcolor border-[0.06rem] border-[#D8EEF8]/20 flex items-center justify-center">
                <img src={img} alt="icon" width={50} height={50} className="object-contain" />
            </div>
            <div className="w-full flex flex-col items-center justify-center px-2 mt-14 gap-1">
                <h1 className="w-full text-[1rem] text-text-light font-medium text-center">{name}</h1>
                <p className="w-full text-sm text-text-dark font-thin text-justify leading-tight">{content}</p>
            </div>
        </div>
    );
};

// Define the ProfileCard component
const ProfileCard = ({ img, name, link }) => {

    const openLink = (url, event) => {
        event.stopPropagation();
        window.open(url, "_blank");
    };

    return (
        <div className="w-[17rem] h-[6.625rem] flex justify-center items-center gap-2">
            <img src={img} alt="profile" width={100} height={100} className="object-contain md:block hidden" />
            <img src={img} alt="profile" width={70} height={70} className="object-contain block md:hidden" />
            <div className="relative w-[11rem] flex justify-center items-center px-2 py-1">
                <div className="w-full flex flex-col items-start justify-center px-2">
                    <h1 className="text-[1rem] text-text-light font-medium">{name}</h1>
                    <div className="w-full flex items-center justify-start pr-2">
                        <div onClick={(event) => openLink(link, event)} className="cursor-pointer">
                            <FaLinkedinIn size={24} style={{ fill: 'url(#gradient)' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Export the components
export { AboutUsCard, ProfileCard };
