"use client";

import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { cn } from "../utils/cn";

const CardStack = ({ items, offset = 10, scaleFactor = 0.06 }) => {
  const [cards, setCards] = useState(items);
  const intervalRef = useRef(null);

  useEffect(() => {
    startFlipping();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const startFlipping = () => {
    intervalRef.current = setInterval(() => {
      setCards((prevCards) => {
        const newArray = [...prevCards]; // create a copy of the array
        newArray.push(newArray.shift()); // move the first element to the end
        return newArray;
      });
    }, 5000);
  };

  return (
    <div className="relative h-60 w-[20rem] md:h-[12rem] md:w-[35rem]">
      {cards.slice(0, 3).map((card, index) => (
        <motion.div
          key={card.id}
          className={cn("absolute bg-[#02080B] border-[0.045rem] border-border h-60 w-[20rem] md:h-[12rem] md:w-[35rem] rounded-3xl px-8 py-6 shadow-xl shadow-[#96D1EB]/10 flex flex-col justify-center gap-6")}
          style={{
            transformOrigin: "center",
          }}
          animate={{
            top: index * -offset,
            scale: 1 - index * scaleFactor, // decrease scale for cards that are behind
            zIndex: cards.length - index, // decrease z-index for the cards that are behind
          }}
        >
          <div className="text-text-light font-thin text-sm md:text-[1rem]">
            {card.content}
          </div>
          <div>
            <p className="text-text-dark font-medium text-sm w-full text-right">
              {card.name}
            </p>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default CardStack;
