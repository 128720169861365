import React from "react";

const BoxIcon = ({ img, title, content }) => {
    return (
        <div className="w-[20rem] h-[9.5rem] relative flex flex-col items-start justify-center gap-2 px-5 py-6 bg-gradient-boxcolor border-[0.045rem] border-border rounded-[1.25rem]">
            <div className="w-[11rem] md:w-[12.125rem] flex flex-col items-start justify-center gap-2">
                <h1 className="w-full font-medium text-[1rem] text-text-light">{title}</h1>
                <p className="w-full text-sm font-thin text-text-dark text-left">
                    {content}
                </p>
            </div>
            <img src={img} alt="logo" width={70} height={70} className="object-contain absolute left-[14.125rem] top-[3.5rem] hidden md:block" />
            <img src={img} alt="logo" width={55} height={55} className="object-contain absolute left-[14rem] top-[4rem] md:hidden block" />
        </div>
    );
};

const CourseBoxIcon = ({ img, heading, content, selectedCourse }) => {
    return (
        <div className="w-[15rem] h-[7.8rem] flex flex-col items-start justify-center gap-2 px-7 py-6 bg-boxcolor border-4 border-border rounded-[0.625rem]">
            <img src={img} alt="logo" width={30} height={30} className="object-contain" />
            <p className="md:text-[1rem] text-sm text-text-light font-extralight">
                {heading} : <span className="text-text-dark">{content[selectedCourse]}</span>
            </p>
        </div>
    );
};

export { BoxIcon, CourseBoxIcon };
