export const CourseHeroSectionDetails = {
    "AppliedQuantum": {
        id: 1,
        title: "Applied Quantum",
        content: "Bridging the gap between academia and industry to foster sustainable innovation. Aligning skills and expertise to meet evolving business challenges",
        img: "/Images/AppliedQuantum.png",
        para: "In the fast-paced digital realm, AI and Quantum technologies drive a surge in new professions, challenging traditional job markets. HR leaders must adapt, fostering personalized learning through workshops, certifications, and online platforms to meet evolving skill demands. With remote work on the rise, investments in online learning are prioritized, addressing the current pandemic landscape. Quacademy meets this demand, offering industry-aligned training programs to empower skilling, up-skilling, and re-skilling initiatives.",
        overview:"The Applied Quantum Computing Training course at QuAcademy is designed to provide students with a comprehensive understanding of the practical applications of quantum computing. By taking this course, students develop foundational skills in quantum mechanics, quantum information and computation, and quantum algorithms and protocols.",
        KeyBenefits: [
            "Interactive classes by industry experts",
            "Training on Prerequisites",
            "Real-time Projects",
            "Job Assistance",
            "Free access to LMS(Learning Management System)",
            "24/7 Support",
            "Industry Recognised Certification"
        ],
        Prerequisites: [
            "Basic knowledge of programming concepts",
            "Familiarity with Python programming language",
            "Familiarity with data structures and algorithms",
            "Basic math knowledge, including calculus, linear algebra, and probability theory",
            "Prior experience with data analysis or machine learning is not required but can be helpful",
            "Basic programming background"
        ],
        ProgramOutcomes: [
            "Understanding of Data Science concepts",
            "Knowledge of Data Science tools and technologies",
            "Real-time Projects",
            "Job Assistance",
            "Free access to LMS(Learning Management System)",
            "Industry Recognised Certification",
            "Understanding of ethical considerations in Data Science",
            "Industry Recognised Certification",
            "Ability to communicate Data Science concepts",
            "Understanding of Machine Learning"
        ]

    },
    "AppliedArtificialIntelligence": {
        id: 2,
        title: "Applied Artificial Intelligence",
        content: "Bridging the gap between academia and industry to foster sustainable innovation. Aligning skills and expertise to meet evolving business challenges",
        img: "/Images/AppliedArtificialIntelligence.png",
        para: "In the fast-paced digital realm, AI and Quantum technologies drive a surge in new professions, challenging traditional job markets. HR leaders must adapt, fostering personalized learning through workshops, certifications, and online platforms to meet evolving skill demands. With remote work on the rise, investments in online learning are prioritized, addressing the current pandemic landscape. Quacademy meets this demand, offering industry-aligned training programs to empower skilling, up-skilling, and re-skilling initiatives.",
        overview:"The Applied Quantum Computing Training course at QuAcademy is designed to provide students with a comprehensive understanding of the practical applications of quantum computing. By taking this course, students develop foundational skills in quantum mechanics, quantum information and computation, and quantum algorithms and protocols.",
        KeyBenefits: [
            "Interactive classes by industry experts",
            "Training on Prerequisites",
            "Real-time Projects",
            "Job Assistance",
            "Free access to LMS(Learning Management System)",
            "24/7 Support",
            "Industry Recognised Certification"
        ],
        Prerequisites: [
            "Basic knowledge of programming concepts",
            "Familiarity with Python programming language",
            "Familiarity with data structures and algorithms",
            "Basic math knowledge, including calculus, linear algebra, and probability theory",
            "Prior experience with data analysis or machine learning is not required but can be helpful",
            "Basic programming background"
        ],
        ProgramOutcomes: [
            "Understanding of Data Science concepts",
            "Knowledge of Data Science tools and technologies",
            "Real-time Projects",
            "Job Assistance",
            "Free access to LMS(Learning Management System)",
            "Industry Recognised Certification",
            "Understanding of ethical considerations in Data Science",
            "Industry Recognised Certification",
            "Ability to communicate Data Science concepts",
            "Understanding of Machine Learning"
        ]
    },
    // "DataScience": {
    //     id: 3,
    //     title: "Data Science",
    //     content: "Bridging the gap between academia and industry to foster sustainable innovation. Aligning skills and expertise to meet evolving business challenges",
    //     img: "/Images/DataScience.png",
    //     para: "In the fast-paced digital realm, AI and Quantum technologies drive a surge in new professions, challenging traditional job markets. HR leaders must adapt, fostering personalized learning through workshops, certifications, and online platforms to meet evolving skill demands. With remote work on the rise, investments in online learning are prioritized, addressing the current pandemic landscape. Quacademy meets this demand, offering industry-aligned training programs to empower skilling, up-skilling, and re-skilling initiatives.",
    //     overview:"The Applied Quantum Computing Training course at QuAcademy is designed to provide students with a comprehensive understanding of the practical applications of quantum computing. By taking this course, students develop foundational skills in quantum mechanics, quantum information and computation, and quantum algorithms and protocols.",
    //     KeyBenefits: [
    //         "Interactive classes by industry experts",
    //         "Training on Prerequisites",
    //         "Real-time Projects",
    //         "Job Assistance",
    //         "Free access to LMS(Learning Management System)",
    //         "24/7 Support",
    //         "Industry Recognised Certification"
    //     ],
    //     Prerequisites: [
    //         "Basic knowledge of programming concepts",
    //         "Familiarity with Python programming language",
    //         "Familiarity with data structures and algorithms",
    //         "Basic math knowledge, including calculus, linear algebra, and probability theory",
    //         "Prior experience with data analysis or machine learning is not required but can be helpful",
    //         "Basic programming background"
    //     ],
    //     ProgramOutcomes: [
    //         "Understanding of Data Science concepts",
    //         "Knowledge of Data Science tools and technologies",
    //         "Real-time Projects",
    //         "Job Assistance",
    //         "Free access to LMS(Learning Management System)",
    //         "Industry Recognised Certification",
    //         "Understanding of ethical considerations in Data Science",
    //         "Industry Recognised Certification",
    //         "Ability to communicate Data Science concepts",
    //         "Understanding of Machine Learning"
    //     ]
    // }
};

export const CourseComingSoonData=[
    {
        id:1,
        name:"Applied Quantum",
        img:require("../images/AppliedQuantumCourse.png"),
        note:"Coming Soon",
        smallpara:"Next Cohort Curriculum and Calendar",
    },
    {
        id:2,
        name:"Applied Artificial Intelligence",
        img:require("../images/AppliedArtificialIntelligenceCourse.png"),
        note:"Coming Soon",
        smallpara:"Next Cohort Curriculum and Calendar",
    },

]