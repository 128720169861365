import React from 'react';

const SimpleHeroSection = ({ title, content }) => {
    return (
        <section className="w-full flex flex-col h-[15rem] items-center justify-center px-5 py-6 md:py-12 md:px-10 gap-10">
            <div className="w-full flex flex-col justify-center items-center gap-2 md:gap-5 px-2">
                <h1 className="w-full md:w-[90%] md:text-4xl text-2xl leading-tight font-medium text-text-light text-center">
                    {title}
                </h1>
                <p className="w-[90%] md:w-[40%] md:text-[1rem] text-sm leading-tight font-thin text-text-dark text-center">
                    {content}
                </p>
            </div>
        </section>
    );
}

export default SimpleHeroSection;
