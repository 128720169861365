import React from 'react'
import Header from '../components/header';
import Footer from '../components/footer';
import { AboutUsPartners, ProfileData, Aboutus, Cards } from "../data/icons";
import { AboutUsCard ,ProfileCard} from '../components/profileCard';
import PartnersBox from "../components/partnersBox";
import TitleContent from '../components/TitleContent';
import CardStack from '../components/cardStack';

export default function aboutUs() {
  return (
    <main className="philosopher bg-primary w-full flex flex-col justify-center items-center">
        
        <Header />
    <div className="w-full widthScreen flex flex-col justify-center items-center">

        <section className="relative w-full width flex flex-col  items-center justify-center px-5 md:px-10 py-20 gap-16 ">
                <div className="w-full flex flex-col items-center justify-center ">
                    <div className="w-full md:w-[50rem] flex flex-col items-center justify-center gap-5">
                        <h1 className="w-full font-medium md:text-4xl  text-2xl text-text-light text-center"> Who we are</h1>
                        <p className="w-full  md:text-[1rem] text-sm  font-thin text-text-dark text-justify">
                            In today&apos;s digital world, the gap between academia and industry is more pronounced than ever. Organizations constantly adapt to technological advancements to stay competitive and deliver exceptional products and services. However, this rapid evolution often leaves a skills gap within the workforce. Technologies such as Artificial Intelligence and Machine Learning are becoming ubiquitous, yet many organizations struggle to find candidates equipped with the necessary skills to harness these innovations effectively. As Quantum technologies are accelerating, we do not want a similar skill gap to be repeated like the current advancements of tech.

                        </p>

                        <p className="w-full  md:text-[1rem] text-sm  font-thin text-text-dark text-justify">

                            At QuAcademy, we recognize this challenge. Founded on the principle of bridging this critical gap, we are committed to preparing individuals with the practical knowledge and expertise demanded by today&apos;s industry leaders. Our courses are meticulously designed to blend academic rigor with real-world applications, ensuring our graduates are not only proficient in cutting-edge technologies but also adept at applying them in practical scenarios.
                        </p>
                    </div>
                </div>
                <div className="relative w-full flex flex-wrap justify-center items-center gap-16 py-5 ">
                    {
                        Aboutus.map((item, index) => (
                            <AboutUsCard key={index} img={item.img} name={item.name} content={item.content} />
                        ))
                    }
                    <div
                        className="absolute -z-10 left-1/2 top-[50%] transform -translate-x-1/2 -translate-y-1/2 md:w-[30rem] md:h-[10rem]"
                        style={{
                            background: "radial-gradient(circle at center, rgba(134,202,233,0.4), transparent)",
                            filter: "blur(100px)",
                        }}
                    ></div>
                </div>



            </section>
            <section className="relative w-full width flex flex-col  items-center justify-center px-2 md:px-10 py-20  gap-10">
                <TitleContent title="What do Governments and Global leaders say about Quantum and AI" content="" />
                <div className="w-full flex flex-col md:flex-row items-center justify-center px-3 ">
                    <div className="w-full md:w-[40%] flex flex-col items-center md:items-start justify-center gap-5">
                        {/* <h1 className="w-full font-medium md:text-[2rem] text-2xl   text-text-light">Our Services</h1> */}
                        <p className="w-full  md:text-[1rem] text-sm  font-thin text-text-dark text-center md:text-left">We are focused on delivering exclusive training on Data Science, Artificial Intelligence and Quantum computing to Students, Job aspirants and Professionals.
                            Our training programs, comprising experienced professionals, are designed in such a way that it meets our vision, which is to provide the finest Applied Quantum, Artificial Intelligence and Data Science Experts globally.</p>

                    </div>
                    <div className="h-[20rem] flex items-center justify-center w-full md:w-[70%] ">
                        <CardStack items={Cards} />
                    </div>
                </div>


            </section>
            <section className="relative w-full width flex flex-col  items-center justify-center px-2 md:px-10 pb-20 gap-10">



<TitleContent title="Our Team" content="" />
<div className="w-full flex flex-wrap justify-center items-center gap-16 py-5 ">
    {
        ProfileData.map((item, index) => (
            <ProfileCard key={index} img={item.img} name={item.name} link={item.link} />
        ))
    }
</div>


{/* <div
    className="absolute -z-10 left-1/2 top-[70%] transform -translate-x-1/2 -translate-y-1/2 md:w-[20rem] md:h-[20rem]"
    style={{
        background: "radial-gradient(circle at center, rgba(37,57,59,1), transparent)",
        filter: "blur(100px)",
    }}
></div> */}

</section>
<section className="relative w-full width flex flex-col  items-center justify-center px-2 md:px-10 pb-20 gap-10">
<div className="w-full border-gradient rounded-lg ">
    <div className="bg-primary w-full flex flex-col justify-center items-start gap-8 py-10">
        <TitleContent title="Our Goal" content="At QuAcademy, our unwavering goal is to break down the barriers between industry and academia by providing organizations with unparalleled access to a pool of highly skilled and expert resources through our top-of-the-line training programs. We are committed to helping organizations achieve their goals and stay ahead of the curve in their respective industries." />
        <TitleContent title="Our Partners" content="" />

        <div className="w-full flex flex-wrap justify-center items-center gap-16  py-5">
            {
                AboutUsPartners.map((partner) => (
                    <div key={partner.id} className="flex ">
                        <PartnersBox img={partner.img} />
                    </div>
                ))
            }
        </div>

    </div>
</div>

</section>

      

    </div>
    <Footer />
</main>
  )
}
