"use client";
import React from 'react';
import { useNavigate } from 'react-router-dom'; // For React Router
import user from "../images/user.png";
import commentimg from "../images/comment.png";

const BoxCourse = ({ name, img, content }) => {
    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    };
    const handleButtonClick = () => {
        navigate('/Courses');
    };

    return (
        <div className="w-[20rem] flex flex-col justify-center items-center">
            <div className="w-full h-[12.5rem] bg-gradient-box rounded-t-[1.5rem] flex justify-center items-center">
                <img src={img} alt="logo" width={150} height={150} className="object-contain" />
            </div>
            <div className="w-full h-[16rem] bg-[#05151B] rounded-b-[1.5rem] flex flex-col justify-center items-center px-4 gap-1 py-1">
                <div className="w-full flex flex-col justify-center items-center h-[10rem] gap-1">
                    <h1 className="font-medium text-[1rem] text-text-light text-center">
                        {name}
                    </h1>
                    <p className="w-full text-sm font-thin text-text-dark text-center">
                        {content}
                    </p>
                </div>
                <button
                      onClick={() => {
                        scrollToTop();
                        
                        handleButtonClick();}}
                    className="px-4 py-2 text-text-light md:text-[1rem] text-sm cursor-pointer rounded-[1rem] border-2 border-text-dark/30 hover:bg-[#1B6B8F]/30"
                >
                    Know more
                </button>
            </div>
        </div>
    );
};

const CourseComingSoon = ({ name, img, note, smallpara, event }) => {
    const navigate = useNavigate();
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
    };
    const handleQuickConnectClick = () => {
        navigate('/Enquiry');
    };

    return (
        <div
            className={`w-full flex flex-col md:flex-row items-center justify-center gap-16 ${event === "true" ? "md:flex-row-reverse" : ""}`}
        >
            <img
                src={img}
                alt="logo"
                width={600}
                height={590}
                className="object-contain md:block hidden"
            />
            <img
                src={img}
                alt="logo"
                width={300}
                height={249}
                className="object-contain block md:hidden"
            />
            <div
                className={`w-full md:w-[31.25rem] flex flex-col justify-center items-start gap-1 relative ${event === "false" ? "md:items-end" : ""}`}
            >
                <h4 className="text-lg md:text-xl font-medium text-text-light">{smallpara}</h4>
                <h1 className="text-2xl md:text-4xl font-medium text-text-light md:mb-4">{name}</h1>
                <h4 className="text-4xl md:text-[5rem] md:h-[4rem] font-medium text-text-light">{note}</h4>
                <p className="text-sm md:text-[1rem] font-thin text-text-dark mb-4">
                    Please fill in form below. We will contact you at the earliest.
                </p>
                <button
                     onClick={() => {
                        scrollToTop();
                        
                        handleQuickConnectClick();}}
                    className="px-4 py-4 bg-border text-text-light w-[10rem] rounded-xl text-center"
                >
                    Quick Connect
                </button>
                <div
                    className={`absolute -z-10 top-[15%] transform -translate-x-1/2 -translate-y-1/2 rounded-full md:w-[25rem] md:h-[25rem] ${event === "false" ? "left-[90%]" : "left-[20%]"}`}
                    style={{
                        background: "radial-gradient(circle at center, rgba(37,57,59,0.6), transparent)",
                        filter: "blur(40px)",
                    }}
                ></div>
            </div>
        </div>
    );
};

const BoxCourseComment = ({ name, comment }) => {
    return (
        <div className="flex flex-col justify-center items-center gap-4 md:gap-3 px-10 py-6 bg-primary shadow-md shadow-[#96D1EB]/40 rounded-3xl">
            <div className="w-[15rem] md:w-[28.06rem] flex justify-between items-center gap-3">
                <div className="flex items-center justify-center gap-1">
                    <img src={user} alt="user" width={24} height={24} className="object-contain" />
                    <div className="flex flex-col items-start justify-center gap-1">
                        <h1 className="font-medium text-[1.25rem] text-text-light">
                            {name}
                        </h1>
                    </div>
                </div>
                <img src={commentimg} alt="comment" width={40} height={39} className="object-contain" />
            </div>
            <p className="w-[15rem] md:w-[28.06rem] text-sm font-thin text-text-dark text-left">
                {comment}
            </p>
        </div>
    );
};

export { BoxCourse, CourseComingSoon, BoxCourseComment };
